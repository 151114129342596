.dropdownList {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0px;


}

.inputData {
    border-radius: 4px;
    display: flex;
    gap: 4px;
    min-width: 0;
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    margin: 2px;
    box-sizing: border-box;
    font-family: Inter;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.2px;
    padding: 3px;
    width: fit-content;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* flex: 30% 1; */
    /* flex-basis: 110px; */

    @media (max-width: 767px) {
        padding: 10px;
    }

    & span {
        /* position: absolute;
        right: 5px;
        top: 0; */
        cursor: pointer;
        padding: 0 2px;

        & svg {
            fill: #000;
            height: 8px;
            width: 8px;
        }

        &:hover {
            background-color: #FFBDAD;
            color: #DE350B;

            & svg {
                fill: #DE350B;

            }
        }
    }
}

.error {
    color: red;
    /* font-size: 10px;
    letter-spacing: 2px; */
    text-transform: none;
}

.inputDropdownList {
    position: absolute;
    background: #fff;
    width: 100%;
    border: 1px solid #d4d4d4;
    z-index: 10;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px 20px 10px 20px;
    border-top: none;
}

.focusDropdownlist {
    width: 100%;
    max-height: 300px;
    z-index: 999;
    border: 1px solid #d4d4d4;
    background: #fff;
    position: absolute;
    overflow-y: auto;
    padding: 0 20px 10px 0px;
    border-top: none;
    margin-top: 6px;

    &>p {
        padding-left: 20px;
        margin: 8px 0 0;

        &:hover {
            cursor: pointer;
            background: #7C3AED;
            color: #fff;

        }
    }
}

.redBorder {
    border: 1px solid red !important;
}

.inputForm {
    position: relative;
    width: 100%;

    & h6 {
        position: absolute;
    }
}

.otherInput {
    position: relative;
}

.addButton {
    position: absolute;
    top: 16px;
    right: 27px;
    background: #fff;
}

.iconHover {
    [data-tooltip] {
        position: relative;
        z-index: 1;
        cursor: pointer;
    }

    /* Tooltip content hidden by default */
    [data-tooltip]:before,
    [data-tooltip]:after {
        transition: all .1s ease-in-out;
        visibility: hidden;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
        opacity: 0;
        pointer-events: none;
    }

    /* Tooltip position */
    [data-tooltip]:before {
        position: absolute;
        bottom: 130%;
        left: 50%;
        margin-bottom: 5px;
        margin-left: -80px;
        padding: 12px;
        width: 170px;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        background-color: #ddd;
        background-color: hsla(0, 2%, 71%, 0.9);
        color: #fff;
        content: attr(data-tooltip);
        text-align: center;
        font-size: 14px;
        line-height: 21px;
    }

    /* Triangle hack for Tooltip bubble */
    [data-tooltip]:after {
        position: absolute;
        bottom: 130%;
        left: 50%;
        margin-left: -5px;
        width: 0;
        border-top: 5px solid #ddd;
        border-top: 5px solid hsla(0, 2%, 60%, 0.9);
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        content: " ";
        font-size: 0;
        line-height: 0;
    }

    /* Tooltip visible on hover */
    [data-tooltip]:hover:before,
    [data-tooltip]:hover:after {
        transition: all .3s ease-in-out;
        visibility: visible;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
    }
}

.inputAndValue {
    border-radius: 50px;
    min-height: 38px;
    border: 1px solid #D8DCE6;
    display: flex;
    align-items: center;
    padding: 3px 15px;
    flex-wrap: wrap;
    overflow: hidden;

    & .inputWrapper {
        flex: 1;
        display: inline-grid;
        margin-left: 10px;

        & input {
            border: 0 !important;
            width: auto;
            height: 100%;
            padding: 0;
            min-width: 70px;

            &:focus {
                outline: unset;
                box-shadow: none;
            }
        }
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted hsla(0, 2%, 71%, 0.9);
}
.yesTool{
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted hsla(0, 2%, 71%, 0.9);
}
.yesTool .tooltiptext{
    visibility: hidden;
    width: 165px;
    background-color: hsla(0, 2%, 71%, 0.9);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 5px;
    position: absolute;
    font-size: 12px;
    z-index: 99;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}
.tooltip .tooltiptext {
    visibility: hidden;
    width: 165px;
    background-color: hsla(0, 2%, 71%, 0.9);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 5px;
    position: absolute;
    font-size: 12px;
    z-index: 99;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
.yesTool .tooltiptext {
    visibility: visible;
    opacity: 1;
}
