/* @import '../../styles/customMediaQueries.css'; */

.content {
  padding: 60px 0;
  background-color: #383f48;
  padding: 20px;
  height: 100%;
}

.headingContainer {
  background: #282f3a;
  box-shadow: 1px 0 5px rgb(0 0 0 / 10%);
}

.heading {
  margin: 0 0 0px;
  font-size: 24px;
  color: #aab3bf;
  line-height: 40px;
  font-weight: 400;
  font-family: 'Source Sans Pro';
  display: block;
  padding: 0.8rem 1.25rem;
  position: relative;
  border-bottom: 1px solid rgba(97, 106, 120, 0.07);

  & a {
    color: unset;
  }
}

.pageTitle {
  text-align: center;
}

.logoLink {
  & svg {
    fill: none;
  }
}

.transactionsWrapper {
  width: 100%;

  & thead {
    /* background-color: #b2b2b2; */

    & th {
      text-align: left;
      /* padding: 5px; */
      font-weight: 500;
      color: #adadad;
    }

    & .idBoxHeading {
      min-width: 200px;
    }

    & .sameBoxWidth {
      min-width: 145px;
    }

    & .titleBox {
      min-width: 160px;
    }
  }

  & tbody {
    /* background-color: #ffffff; */

    & td {
      text-align: left;
      padding: 5px;
      font-weight: 400;
      font-size: 14px;
      border: 1px solid #3e4e56;
      color: #cfcfcf;
    }
  }

  & tfoot {
    /* background-color: #ffffff; */

    & th {
      text-align: center;
      padding: 5px;
      font-weight: 400;
      font-size: 14px;
      border: 1px solid #f3f4f6;
    }
  }
}

.action {
  position: relative;
}

.menuItems {
  position: absolute;
  top: 90%;
  /* left: 0; */
  right: 0;
  min-width: 300px;
  background-color: #ffffff;
  z-index: 2;
  box-shadow: 10px 10px 15px 10px rgba(221, 221, 221, 0.75);
  padding: 10px;

  & p {
    cursor: pointer;
  }

  & :hover {
    color: #f8692c;
  }
}

.txDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;
  margin: 0 auto;
  padding: 20px;
  width: 900px;
  box-shadow: 10px 10px 15px 10px rgba(221, 221, 221, 0.75);
  border: 1px solid #000000;

  & p {
    & span:first-child {
      font-weight: 800;
    }
  }
}

.skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.searchBar {
  background: #fff;
  display: flex;
  border-radius: 3px;
  margin-bottom: 30px;
  margin: 0.8rem 1.25rem;

  & .searchInput {
    flex: 1;
    position: relative;

    & input {
      border: none;
      padding: 5px 15px;
    }

    & .clearSearch {
      position: absolute;
      right: 10px;
      top: 6px;
      cursor: pointer;
    }
  }

  & .searchButton {
    border: 1px solid var(--successColor);
    background-color: var(--successColor);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    cursor: pointer;

    @media(max-width:768px) {
      width: 60px;
    }

    & .mobileSearch {
      display: none;

      @media(max-width:768px) {
        display: block;
      }

      & svg {
        width: 20px;
        height: 20px;
        stroke: #fff;
        fill: #fff;
      }
    }

    & .desktopSearch {
      display: block;

      @media(max-width: 768px) {
        display: none;
      }
    }
  }
}

.approvalButton {
  background-color: var(--successColor);
  color: var(--matterColorLight);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: capitalize;
  display: inline-block;
  width: 100px;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  filter: unset;
  min-height: 40px;
}

.viewButton {
  border: none;
  cursor: pointer;
  text-align: center !important;

  & svg {
    & path {
      stroke: #fff;
    }
  }
}

.pagination {
  justify-content: center;
}

.mainWrapper {
  /* width: 90%;
  margin: 0 auto; */
  padding: 0.8rem 1.25rem;
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}