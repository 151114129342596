.iconHover {
  [data-tooltip] {
    position: relative;
    z-index: 200;
    cursor: pointer;
  }

  /* Tooltip content hidden by default */
  [data-tooltip]:before,
  [data-tooltip]:after {
    transition: all 1.3s ease-in-out;
    visibility: hidden;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
  }

  /* Tooltip position */
  [data-tooltip]:before {
    position: absolute;
    bottom: 130%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -80px;
    padding: 12px;
    width: 170px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    background-color: #ddd;
    background-color: hsla(0, 2%, 71%, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 14px;
    line-height: 21px;
  }

  /* Triangle hack for Tooltip bubble */
  [data-tooltip]:after {
    position: absolute;
    bottom: 130%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #ddd;
    border-top: 5px solid hsla(0, 2%, 60%, 0.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: ' ';
    font-size: 0;
    line-height: 0;
  }

  /* Tooltip visible on hover */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    transition: all 1.3s ease-in-out;
    visibility: visible;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

:global(#react-select-56-listbox) {
  z-index: 10;
}
:global(.css-1hui0kr-menu) {
  z-index: 10;
}

.dropDownWrapper {
  & > div {
    position: relative;
    z-index: 10;
  }
}
.yesTool{
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted hsla(0, 2%, 71%, 0.9);
}
.yesTool .tooltiptext{
  visibility: hidden;
  width: 165px;
  background-color: hsla(0, 2%, 71%, 0.9);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 5px;
  position: absolute;
  font-size: 12px;
  z-index: 99;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}