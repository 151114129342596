@import '../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  /* fill */
  background-color: #2f2d2b;

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
  & img {
    margin: 0;
    width: 165px;
    height: 46px;
    flex-shrink: 0;
  }
}

.logoLink:hover {
  /* border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0; */
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  margin-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    margin-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  transition: var(--transitionStyleButton);

  margin-left: 20px;
  border-radius: 100px;
  border: 1px solid #fff;
  height: 40px;
  padding: 14px 0;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: 'Source Serif 4';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;

  @media (--viewportLarge) {
    width: 190px;
    height: 50px;
  }

  &:hover {
    color: var(--marketplaceColorDark);
    /*   border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0; */
    text-decoration: none;
  }

  @media (--viewportLarge) {
    margin-left: 40px;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }

  & span {
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.72px;

    @media (--viewportLarge) {
      font-size: 18px;
    }
  }
}



.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  color: #fff;
  text-align: center;

  font-family: 'Source Serif 4';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;
  text-decoration: none;
  margin-left: 21px;
  margin-right: 31px;
  position: relative;

  @media (--viewportLarge) {
    font-size: 18px;
  }

  &:hover {
    color: #fff;
    /* border-bottom: 4px solid var(--marketplaceColor); */
    /* border-radius: 0; */
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin: 0; */
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: -5px;
  right: -4px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding-left: 12px;
  padding-right: 0;

  @media (--viewportLarge) {
    padding: 0 12px 0 12px;
  }

  &:hover {
    /* border-bottom: 4px solid var(--marketplaceColor); */
    /* border-radius: 0; */
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
  width: 51px;
  height: 51px;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.loginLink {
  transition: var(--transitionStyleButton);

  border-radius: 50px;
  border: 1px solid #fff;
  background: #fff;
  width: 170px;
  text-align: center;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 36px auto;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    /* border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0; */
    text-decoration: none;
  }

  & span {
    color: #2f2d2b;
    text-align: center;
    font-family: 'Source Serif 4';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.72px;
  }
}

.signup,
.login {
  composes: textSmall from global;

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.rightBoxHeader {
  display: flex;
  align-items: center;
  height: 100%;

  & .menuLinks {
    display: flex;
    & .navLink {
      padding-left: 15px;
      position: relative;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.18px;
      text-decoration: none;
      font-family: 'Quicksand';

      @media (--viewportLarge) {
        padding-left: 20px;
        font-size: 18px;
      }
    }
  }
}

.logoWrapper {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border: 1px solid #fff;
  overflow: hidden;
  display: inline-block;
  & img {
    object-fit: contain;
    height: 28px;
    width: 28px;
    min-width: 28px;
  }
}

.coinWrapper {
  position: relative;
  & .coins {
    position: absolute;
    z-index: 2;
    right: -11px;
    top: -7px;
    background: #fff;
    color: #000;
    border-radius: 50%;
    aspect-ratio: 14/14;
    min-width: 20px;
    min-height: 20px;
    display: block;
    padding: 2px;
    line-height: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    border: 4px solid #ed9800;
    &.Largecoins {
      right: -22px;
      top: -29px;
    }
  }
}

.loginMenu {
  margin-left: 43px;
}

.loginmenuWrapper {
  & > div {
    background: var(--Forteh---Prime---Black, #2f2d2b);
    width: 393px;
    border: 0;
    border-bottom-left-radius: 50px;
    top: 50px;
    padding-bottom: 86px;
    & > div:first-child {
      display: none !important;
    }
    & ul {
      text-align: center;
    }
  }
}


.socialMediaLinks{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 17px;
  margin: 20px 0;
  flex: 1;
}

.menuLinks2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px !important;
  margin-bottom: 36px;
  & a {
    color: #fff;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.18px;
  }
}

.menuClose{
  position: relative;
  & .close{
    position: absolute;
    right: 16px;
    top: -5px;
  }
}