.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;
  /* Remove link's hover effect */
  @media (max-width: 767px) {
    padding: 0 0px 0 0;
  }
  &:hover {
    text-decoration: none;
  }
}

.cardroot {
  max-width: 366px;
  display: block;
  width: 100%;
  margin-bottom: 98px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
  &:hover {
    text-decoration: none;
  }
}

.borderRadius {
  border-radius: 10px;
  background: #fff;
  box-shadow: 8px 3px 12px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.skills {
  padding: 24px 14px;
  background: #fcf6eb;
  display: flex;
  gap: 12px;
  max-width: 100%;

  & > span {
    border-radius: 100px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 18px;
    color: #4a4a4a;
    white-space: nowrap;
  }
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cardContent {
  display: flex;
  gap: 21px;
  height: 165px;
  @media (max-width: 767px) {
    height: auto;
    align-items: center;
  }
}

.cardRightContent {
  padding: 18px 17px 0 0;
  & h4 {
    color: #2f2d2b;
    font-family: Source Serif 4;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.4px;
    margin: 0;
  }
  & h5 {
    color: #2f2d2b;
    font-family: Source Serif 4;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.28px;
    margin: 0;
  }
  & ul {
    margin-top: 9px;
    list-style: disc;
    margin-left: 20px;
    margin-top: 0px;
  }
  & li {
    color: #2f2d2b;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    padding: 0;
    letter-spacing: 0.28px;
  }
}

.cardImg {
  border-radius: 10px 50px 0px 0px;
  max-width: 127px;
  width: 127.719px;
  height: 163.938px;
  position: relative;
  & img {
    width: 100%;
    height: 163.938px;
    border-radius: 10px 50px 0px 0px;
  }
}

.price {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.305px;
  margin: 10px 0 5px;
  padding: 0;
}

.cardType {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.shareBtn {
  display: flex;
  max-width: 156px;
  width: 100%;
  height: 42px;
  min-height: 42px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.267px;
  @media (max-width: 767px) {
    max-width: 140px;
  }
}

.viewNoteBtn {
  display: flex;
  max-width: 156px;
  width: 100%;
  height: 42px;
  min-height: 42px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background-color: #2f2d2b;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.267px;
  @media (max-width: 767px) {
    max-width: 140px;
  }
}

.closeIcon {
  position: absolute;
  top: 5px;
  right: 2px;
  cursor: pointer;
}

.shareModal {
  & .modalImg {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 170px;
    & .modalInnerImage{
      position: relative;
      width: 170px;
      height: 170px;
    }
    @media (min-width: 768px) {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
    }

    & img {
      width: 170px;
      height: 170px;
      border-radius: 170px;
      object-fit: cover;
    }
  }
  & .modalMain {
    max-height: 580px;
    overflow-y: scroll;
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 5px;
    padding-bottom: 20px;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      background: #f9d3c7;
    }

    &::-webkit-scrollbar-thumb {
      background: #ffaa8c;;
      border-radius: 6px;
      outline: 1px solid #fff;
    }
    & .modalHeading {
      color: #000;
      text-align: center;
      font-family: 'Source Serif 4', serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 33px;
      /* margin-top: 40px; */
    }
    & .textField {
      width: 100%;
      height: 397px;
      border-radius: 8px;
      background: #fcf6eb;
      resize: none;
      padding: 25px 25px 25px 17px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      max-height: 397px;
      min-height: 397px;
      overflow-y: auto;
    }
    & .editNoteBtn {
      border-radius: 100px;
      border: 1px solid #2f2d2b;
      background-color: #fff;
      color: #3c434d;
      text-align: center;
      font-family: 'Source Serif 4', serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      max-width: 387px;
      width: 100%;
      height: 54px;
      margin-top: 13px;
      & svg{
        stroke: #000;
      }
    }
    & .sendNoteBtn {
      border-radius: 100px;
      border: 1px solid #2f2d2b;
      background-color: #2f2d2b;
      color: #fff;
      text-align: center;
      font-family: 'Source Serif 4', serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      max-width: 387px;
      width: 100%;
      height: 54px;
      margin-top: 31px;
    }
    & .viewProfileNoteBtn {
      border-radius: 100px;
      border: 1px solid #2f2d2b;
      background-color: #fff;
      color: #3c434d;
      text-align: center;
      font-family: 'Source Serif 4', serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      max-width: 387px;
      width: 100%;
      height: 54px;
      min-height: 54px;
      margin-top: 30px;
    }
  }
}

.shareModal {
  & > div {
    & > div {
      @media (min-width: 768px) {
        flex-basis: 488px !important;
        border: none !important;
        filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25));
        padding: 55px 53px 55px 53px !important;
      }
      & > div {
        max-height: none;
      }
    }
  }
}

.emailInput {
  width: 100%;
  margin-top: 16px;
}
