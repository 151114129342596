@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);

  @media (max-width: 500px) {
    width: 100%;
    margin-bottom: 24px;
  }
}

.lastNameRoot {
  width: calc(50% - 9px);

  @media (max-width: 500px) {
    width: 100%;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  @media (--viewportMedium) {
    margin-top: 38px;
  }
}

input {
  color: #4a4a4a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.305px;
  height: 40px;
}

select {
  border-radius: 50px;
  box-shadow: none;
  height: 40px;
  padding: 0 12px;
}

.mainProfessionField {
  margin-top: 32px;
}

.radioRow {
  display: flex;
  gap: 50px;

  @media (--viewportMedium) {
    gap: 100px;
    margin-bottom: 22px;
  }
}
.passwordWrapper {
  position: relative;
  margin-top: 30px;
}
.passwordInputBox {
  margin-bottom: 15px;
}
.passwordShow {
  position: absolute;
  cursor: pointer;
  top: 36px;
  right: 15px;
  z-index: 10;
  text-decoration: underline;
  color: var(--marketplaceColorLight);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

.inputField,
.firstNameRoot,
.lastNameRoot,
.mainProfessionField,
.passwordInputBox {
  position: relative;
  & > div {
    position: absolute;
    top: 100%;
    line-height: 20px;
    margin-top: 4px;
    font-size: 12px;
    white-space: nowrap;
  }
}
